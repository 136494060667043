import {WcLogChipping} from "../types/data-types";
import {PostToPMIS} from "./service-http-post";
import {PmisResponseType} from "./service-global";


export const DeleteChippingLog=async (action:string,table:string,record:WcLogChipping,feedback:any)=>{
    let endpoint=`/data/${action}/${table}`
    let data:any={}
    data.Org = record.Org;
    if(table!=="sale"){
        data.Record = record
    }else{
        data.RecordSale = record
    }
    console.log(":)DeleteChippingLog  send> ",data)
    await PostToPMIS(data,endpoint,(response:any)=>{
        console.log(":)DeleteChippingLog  response> ",response)
        let info :PmisResponseType = response as PmisResponseType
        feedback(info.RESULT)
    })

}