import styled from "styled-components";

interface Props{
    title:string
    bgColor:string
    labelColor:string
    onClick:any
    height:number
    width?:number
    radius:number
    noSign?:boolean
}
export default (props:Props)=>{

    return(
        <Container {...props}>
            {!props.noSign?
            <Label1 color={props.labelColor}>+</Label1>
                :null
            }
            <Label2 color={props.labelColor}>{props.title}</Label2>
        </Container>
    )
}
const Label1 = styled.div<{color:string}>`
  font-weight: bold;
  font-size: 16px;
  color: ${(props)=>props.color};
`
const Label2 = styled.div<{color:string}>`
  font-weight: bold;
  font-size: 16px;
  color: ${(props)=>props.color};
`

const Container = styled.div<{bgColor:string,height:number,radius:number,width?:number}>`
    width: ${(props)=>props.width?props.width:"100%"};
  height: ${(props)=>props.height}px;
  border-radius: ${(props)=>props.radius}px;
  background-color: ${(props)=>props.bgColor};justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  cursor: pointer;
  
`