import styled from "styled-components";

interface Props {
    message: string
    color: string
    fontSize:number
}

export default (props: Props) => {
    return (
        <Label color={props.color} fontSize={props.fontSize}/>
    )
}
const Label = styled.label<{
    color: string,
    fontSize: number,
}>`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color}px;
`