import styled from "styled-components";
import Colors from "../../constants/Colors";
import {GetDimensions} from "../../services/service-global";
import IconContainer from "../../assets/images/IconContainer";
import imgShoppingBag from "../../assets/images/IconShoppingBag.png";
import imgCategory from "../../assets/images/IconCategory.png";
import imgMenu from "../../assets/images/IconMenu.png";
import React from "react";


interface Props{
    title:string
}
const {width,height}=GetDimensions()
export default (props:Props)=>{

    return(
            <Container>
                <ContainerIconLabel>
                    <Label3>
                        CMIS
                    </Label3>
                </ContainerIconLabel>
                <Label2>{props.title}</Label2>
                <GroupIcons justifyContent={"flex-end"}>
                    <IconContainer Img={imgCategory} size={18}/>
                </GroupIcons>
            </Container>

    )
}

const Label3 = styled.label`
  color: ${Colors.brand.red};
  font-style: italic;
  font-size: 14px;
`
const ContainerIconLabel = styled.label`
    width: 45px;
  height: 45px;
  border-radius: 22.25px;
  background-color: ${Colors.brand.white};
  margin: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const Label2 = styled.label`
    font-style: italic;
  color:  ${Colors.brand.white};
`
const GroupIcons =styled.div<{justifyContent:string}>`
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props)=>props.justifyContent} ;
`
const Container =styled.div`
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: 10px;
  min-width: 100%;
  height: 50px;
  background-color: ${Colors.brand.blue};
  align-items: center;
`
const  BigContainer=styled.div`
    display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${Colors.brand.blue};
  width: ${width};
  height: 50px;
`