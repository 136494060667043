import styled from "styled-components";
import img from "../../assets/images/IconMenu.png"

type Props = {
    Img: any
    onClick?: any
    size?: number

}
export default (props: Props) => {
    const MyIcon = styled.img`
      display: flex;
      width: ${!props.size?"34":props.size}px;
      height: ${!props.size?"34":props.size}px;
      padding: 7.25px 6.25px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

    `
    return (
        <MyIcon src={props.Img} onClick={props.onClick}/>
    )
}

