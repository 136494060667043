import styled from "styled-components";
import Colors from "../../constants/Colors";

interface Props {
    list: { key: string, value: any }[]
}

export default (props: Props) => {

    return (
        <Container>
            {props.list.map(item => {
                return (
                    <ContainerRow>
                        <LabelKey>{item.key}:</LabelKey>
                        <LabelValue>{item.value}</LabelValue>
                    </ContainerRow>
                )
            })}
        </Container>
    )
}

const LabelValue = styled.label`
  color: ${Colors.brand.blue};
`

const LabelKey = styled.label`
  color: ${Colors.brand.blue};
`
const ContainerRow = styled.div`
  border: 1px solid ${Colors.brand.lightBlue};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 25px;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
`
const Container = styled.div`
  width: 95%;
  margin-top: 15px;
`