import styled from "styled-components";
import moment from "moment";
import Colors from "../../constants/Colors";


interface Props {
    data: string[]
    selectedDate: string
    format: string
    bgColor: string
    bgColorSelected: string
    labelColor: string
    labelColorSelected: string
    onPress: any
}

export default (props: Props) => {

    return (
        <Container>
            {props.data.map(item => {
                let lbColor = props.labelColor;
                let bgColor = props.bgColor
                if (item === props.selectedDate) {
                    lbColor = props.labelColorSelected;
                    bgColor = props.bgColorSelected
                }
                return (
                    <ButtonDate labelColor={lbColor} bgColor={bgColor} onClick={() => props.onPress(item)}>
                        {moment(item, "YYYY-MM-DD").format(props.format)}
                    </ButtonDate>
                )
            })}
        </Container>
    )
}

const ButtonDate = styled.button<{ bgColor: string, labelColor: string }>`
  height: 35px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.labelColor};
  font-size: 14px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  border-color: ${Colors.brand.green};
`
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
  overflow-x: scroll;
`