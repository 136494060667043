import styled from "styled-components";
import Colors from "../../constants/Colors";
import {useState} from "react";


interface Props{
    label:string,
    onChange:any,
    type?:string
    labelColor?:string
    borderColor?:string
    value?:any
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props:Props)=>{

    const [InputValue,setInputValue]=useState("")
    const handleInputChange = (e:any) => {
        let inputValue = e.target.value;
        // Use a regular expression to allow only numeric characters
        if(props.type==="numeric"){
            inputValue = inputValue.replace(/[^0-9]/g, '');
        }
        // Update the parent component's state with the numeric value
        props.onChange(inputValue);
        setInputValue(inputValue);
    };
    return(
        <Container>
            <Label1 color={props.labelColor?props.labelColor:Colors.brand.blue}>
                {props.label}  {typeof props.value!=="undefined"?`[Old value: ${props.value}]`:null}
            </Label1>
            <InputContent
                onChange={(e:any)=>handleInputChange(e)}
                type={props.type?props.type:"text"}
                borderColor={props.borderColor?props.borderColor:Colors.brand.lightBlue}
                value={InputValue}
            />
        </Container>
    )
}

const InputContent=styled.input<{borderColor:string}>`
  height: 35px;
  border-radius: 10px;
  border-color: ${(props)=>props.borderColor};
`
const Label1=styled.label<{color:string}>`
    font-weight: bold;
  font-size: 16px;
  color: ${(props)=>props.color};
`
const Container=styled.div`
    display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 95%;
  margin-top: 10px;
  
    `