// @ts-ignore
import Cookies from 'js-cookie';


export const SaveCookiesJson=(cookieKey:string, cookieValue:any)=>{
    let str = JSON.stringify(cookieValue)
    Cookies.set(cookieKey,str);
}
export const GetCookiesJson=(cookieKey:string):any=>{
   let valueFetch =  Cookies.get(cookieKey) || "{}";
    return JSON.parse(valueFetch)
}
export const CheckIFCookieExist=(cookieKey:string):boolean=>{
    return Cookies.get(cookieKey) !== undefined;
}