import styled from "styled-components";

interface Props{
    title:string
    color:string
}
export default (props:Props)=>{
    return(
        <Label color={props.color}>
            {props.title}
        </Label>
    )
}
const Label = styled.label<{color:string}>`
  margin-top: 10px;
    color:${(props)=>props.color};
  font-weight: bold;
  font-size: 26px;
    `