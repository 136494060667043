// @ts-ignore
import {
    SET_CLIENTS_STATE,
    SET_COMPANIES_STATE, SET_ITEMS_STATE,
    SET_LOGIN_STATE, SET_SELECTED_CLIENT, SET_SELECTED_COMPANY, SET_SELECTED_ITEM
} from "./constant";
import {ClientType, CompanyType, ItemType, LoginType} from "../types/general-types";
import {initialCompanyType, initialLoginType} from "../types/initialize-types";

type defaultStateType = {
    LoginState: LoginType,
    Companies: CompanyType[],
    Clients:ClientType[],
    Items:ItemType[],
    SelectedCompany:CompanyType,
    SelectedClient:string,
    SelectedItem:string,
}
let defaultState: defaultStateType = {
    LoginState: initialLoginType,
    Companies: [],
    Clients:[],
    Items:[],
    SelectedCompany:initialCompanyType,
    SelectedClient:"",
    SelectedItem:"",
}

// @ts-ignore
export default function rootReducer(state = defaultState, {type, payload}: { type: string, payload: any }): any {
    let newState = state;
    switch (type) {
        case SET_COMPANIES_STATE:
            newState.Companies = payload as CompanyType[];
            return Object.assign({}, newState);
        case SET_CLIENTS_STATE:
            newState.Clients = payload as ClientType[];
            return Object.assign({}, newState);
        case SET_LOGIN_STATE:
            console.info("SET_LOGIN_STATE ::::) ",payload)
            newState.LoginState = payload as LoginType;
            return Object.assign({}, newState);
        case SET_ITEMS_STATE:
            newState.Items = payload as ItemType[];
            return Object.assign({}, newState);
        case SET_SELECTED_COMPANY:
            newState.SelectedCompany = payload as CompanyType;
            return Object.assign({}, newState);
        case SET_SELECTED_CLIENT:
            newState.SelectedClient = payload as string;
            return Object.assign({}, newState);
        case SET_SELECTED_ITEM:
            newState.SelectedItem = payload as string;
            return Object.assign({}, newState);
    }
    return state
}
