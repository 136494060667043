import styled from "styled-components";
import {faClock, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Colors from "../../constants/Colors";


interface Props {
    project: string
}

export default (props: Props) => {
    return (
        <Container>
            <StyledIcon icon={faArrowRight}/>
            <Label0>Project: </Label0>
            <Label1>
                {props.project}
            </Label1>
        </Container>
    )
}

const Label0 = styled.label`
  font-size: 16px;
  font-weight: bold;
`
const Label1 = styled.label`
  font-size: 16px;
  margin-left: 5px;
  color: ${Colors.brand.red}
`
const StyledIcon = styled(FontAwesomeIcon)`
  /* Add your styles here */
  color: ${Colors.brand.yellow}; /* Set the desired color */
  font-size: 1rem; /* Set the desired font size */
  margin-right: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`