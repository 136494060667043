import React from 'react';
// @ts-ignore
import {BrowserRouter as Router, HashRouter, Route, Switch} from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./redux/store";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import NewLogChippingPage from "./pages/NewLogChippingPage";
import HomeDetailLogsPage from "./pages/HomeDetailLogsPage";
import HomeDetailLogEditPage from "./pages/HomeDetailLogEditPage";
import NewLogSalePage from "./pages/NewLogSalePage";
import NewLogChippingCsvPage from "./pages/NewLogChippingCsvPage"

function App() {
    return (
        <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/home" component={HomePage}/>
                <Route exact path="/home-detail-logs" component={HomeDetailLogsPage}/>
                <Route exact path="/home-detail-log-edit" component={HomeDetailLogEditPage}/>
                <Route exact path="/new-log-chipping" component={NewLogChippingPage}/>
                <Route exact path="/new-log-chipping-csv" component={NewLogChippingCsvPage}/>

                <Route exact path="/new-log-sale" component={NewLogSalePage}/>
                <Route exact path="/" component={LandingPage}/>
                <Route exact path="*" component={NotFoundPage}/>

            </Switch>
        </Router>
        </Provider>
    );
}

export default App;
