import styled from "styled-components";
import Colors from "../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// In your main file or where you set up global styles
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCalendar,faClock,faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';

library.add(faCalendar);
library.add(faClock)
interface Props{
    title:string
    startDate :string,
    endDate?:string
    TotalService:number,
    type:string,
    category:string
    onClick:any,

    detailRefLabel:string
    detailQuantityLabel:string
    targetDate:string

}

export default (props:Props)=>{
    return(
        <Container onClick={()=>props.onClick(
            props.type,
            props.category,
            props.title,
            props.detailRefLabel,
            props.detailQuantityLabel
        )}>
            <GroupLabel1>
                <Label1>
                    {props.title}
                </Label1>
                <Label2>
                     {props.startDate}
                </Label2>
            </GroupLabel1>
            <GroupTotal>
                <Label3>
                    {props.TotalService}
                </Label3>
                <Label2>
                    <StyledIcon icon={faArrowCircleRight}/>
                </Label2>
            </GroupTotal>
        </Container>
    )
}

const StyledIcon = styled(FontAwesomeIcon)`
  /* Add your styles here */
  color: ${Colors.brand.blue}; /* Set the desired color */
  font-size: 1rem; /* Set the desired font size */
  margin-right: 10px;
`;
const Label3 = styled.label`
    font-size: 24px;
  font-weight: bold;
  color: ${Colors.brand.lightRed};
  
`
const GroupTotal =styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Label2 = styled.label`
    font-size: 14px;
  font-weight: normal;
  color: ${Colors.brand.green};
  
`
const Label1 = styled.label`
    font-size: 18px;
  font-weight: normal;
  color:${Colors.brand.gray};
  
`
const GroupLabel1 =styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${Colors.brand.white};
  align-items: center;
  width: 95%;
  border: 1px solid ${Colors.brand.gray};
  border-radius: 5px;
 padding: 5px;
  margin-top: 10px;
  cursor: pointer;
    `