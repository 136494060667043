import React, {useState} from "react";
import Page from "./Page";
import styled from "styled-components";
import Colors from "../constants/Colors";
import {GetDimensions, goTo} from "../services/service-global";
import PmisLogo1 from "../assets/images/PmisLogo2.png"

const {width, height} = GetDimensions()

export default (props: any) => {

    const clickStart=()=>{
        //alert("Let start")
        goTo("/login")
       // window.location.href = "/login"
    }
    return (
        <Page>
            <Container>

                <ContainerLogo src={PmisLogo1}>

                </ContainerLogo>
                {/*<CircleContainer>
                    <Label1>CMIS</Label1>
                </CircleContainer>*/}

                <ContainerLabel1>
                    <Label2>WOODCHIPPING</Label2>
                </ContainerLabel1>

                <ContainerLabel1>
                    <Label3>Delivery Note Logging System</Label3>
                </ContainerLabel1>


                <ContainerLabel2>
                    <Label4>
                        The purpose of this service is to make sure that
                        the invoice will be generate on time.
                        You can check on the dashboard logs for today entry.
                    </Label4>
                </ContainerLabel2>

                <ContainerBtn onClick={clickStart}>
                    <Label5>Let's start</Label5>
                </ContainerBtn>

            </Container>

        </Page>
    )
}

const ContainerLogo=styled.img`
  width: 80%;
  
`
const Label5 = styled.label`
    text-align: center;
  color: ${Colors.brand.red};
`
const ContainerBtn=styled.div`
    width: 40%;
    height: 30px;
  background-color: ${Colors.brand.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`

const Label4 = styled.label`
  font-weight: normal;
  font-size: 16px;
  color: ${Colors.brand.white};
  padding:30px;
  text-align: justify;
`

const ContainerLabel2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`

const ContainerLabel1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`
const Label2 = styled.label`
  font-weight: bold;
  font-size: 24px;
  font-style: normal;
  color: ${Colors.brand.white}
`
const Label3 = styled.label`
  font-weight: normal;
  font-size: 18px;
  font-style: normal;
  
  color: ${Colors.brand.white}
`
const Label1 = styled.label`
  font-weight: bold;
  font-size: 24px;
  font-style: italic;
`
const CircleContainer = styled.circle`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: ${Colors.brand.white};

  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  background-color: ${Colors.brand.blue};
  width: ${width - 20}px;
  min-height: ${(props) => height + "px"};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 768px;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
`
