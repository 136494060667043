import styled from "styled-components";
import {GetDimensions} from "../../services/service-global";
import {SvgBottomNavHome, SvgBottomNavPackage} from "../../assets/avgs/constant-icons";

interface Props{
    selectedItem?:string
    onSelect?:any
}
const {width, height} = GetDimensions()
export default (props:Props) => {
    const isSelectedByUser=(labelName:string)=>{
        return props.selectedItem==labelName
    }
    const getSelectedColor=(inVal:string):string=>{
        if(isSelectedByUser(inVal)){
            return  "#228A42"
        }
        return ""
    }
    const RenderIcon = (label: string, myIcon: any) => {
        //const myIcon = require(`../../assets/images/${iconName}`)
        let color = "#A4AFAF"
        if(isSelectedByUser(label)){
            color = "#228A42"
        }
        const MyStyledSVG = styled(myIcon)`
          fill: ${color}; /* Set the fill color here */
          /* You can add other CSS properties as needed */
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        `;
        let currIconAvg = myIcon;

        return (
            <IconBox onClick={()=>props.onSelect(label)}>
                <MyAvg MyColor={color}>{currIconAvg}</MyAvg>
                <LabelIcon isSelected={props.selectedItem==label}>
                    {label}
                </LabelIcon>
            </IconBox>
        )
    }
    return (
        <Container>
            {RenderIcon("Home",SvgBottomNavHome,)}
            {RenderIcon("Report",SvgBottomNavPackage  )}
            {RenderIcon("Track",SvgBottomNavPackage)}
        </Container>
    )
}

const MyAvg = styled.svg<{MyColor: string }>`
  fill: ${(props)=>props.MyColor}; /* Set the fill color here */
  /* You can add other CSS properties as needed */
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`
const LabelIcon = styled.label<{ isSelected: boolean }>`
  color:${(props)=>!props.isSelected? "#A4AFAF":"#228A42"};
  text-align: center;
  font-family: Lexend Deca;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
const IconBox = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(width / 3)-20}px;
  height: 72px;
  background-color: transparent;
`
const Container = styled.div`
  width: ${width-20}px;
  max-width: 768px;
  min-height: 72px;
  flex-shrink: 0;
  border-top: 1px solid #CCD1DE;
  background: var(--primary-white, #FFF);
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0px;
  justify-content: space-between;
  
`