import styled from "styled-components";
import TopBar2 from "../compoents/common/TopBar2";
import React, {useEffect, useState} from "react";
import NavBarBackCancel from "../compoents/common/NavBarBackCancel";
import PageTitle from "../compoents/common/PageTitle";
import Colors from "../constants/Colors";
import {buildComboDriver, buildComboVehicle, FetchDataConfig, GetDimensions, goTo} from "../services/service-global";
import {GetStorageRecord} from "../services/service-locastorage";
import {
    SelectedDashboardLogsCategory,
    SelectedDashboardLogsDetail,
    SelectedDashboardLogsDetailEdit,
    SelectedDashboardLogsTitle
} from "../constants/common";
import {ConfigData, WcLogChipping, WcLogSale} from "../types/data-types";
import {initialConfigData, initialWcLogChipping, initialWcLogSale} from "../types/initialize-types";
import {GetCurrentLoginState} from "../services/service-auth";
//import InfoBarSelectedProject from "../compoents/common/InfoBarSelectedProject";
import BoxSelectAutoComplete from "../compoents/common/BoxSelectAutoComplete";
import DateLabelForm from "../compoents/common/DateLabelForm";
import InputLabelForm from "../compoents/common/InputLabelForm";
import ListKeyVal from "../compoents/common/ListKeyVal";
import moment from "moment/moment";
import {DeleteChippingLog} from "../services/service-chipping-logs";

const {width, height} = GetDimensions()

export default (props: any) => {
    const [DataConfig, setDataConfig] = useState<ConfigData>(initialConfigData)
    const [Sync, setSync] = useState(false)
    const [LogSelected, setLogSelected] = useState<WcLogChipping>(initialWcLogChipping)
    const [LogSaleSelected, setLogSaleSelected] = useState<WcLogSale>(initialWcLogSale)

    const [LoginState, _] = useState(GetCurrentLoginState())
    const [category, setCategory] = useState("")

    const [InputCubicSize, setInputCubicSize] = useState("")
    const [InputLoadMass, setInputLoadMass] = useState("")
    const [InputQty, setInputQty] = useState("")


    let targetWidth = width
    if (width > 768) {
        targetWidth = 768
    }

    useEffect(() => {
        if (!Sync) {
            loadLogs().then(null)
            loadDataConfig().then(null)
            setSync(true)
        }
    })

    const loadDataConfig = async () => {
        await FetchDataConfig(LoginState.Org, LoginState.Username, (res: any) => {
            let data = res as ConfigData
            setDataConfig(data)
        })
    }
    const loadLogs = async () => {
        let logsCategory = await GetStorageRecord(SelectedDashboardLogsCategory);
        setCategory(logsCategory)
        let logsDetailString = await GetStorageRecord(SelectedDashboardLogsDetailEdit)
        console.log("SelectedDashboardLogsDetailEdit >>>> Cat: ", logsCategory, " >>>", logsDetailString)
        if (logsCategory === "sale") {
            let logs = logsDetailString as WcLogSale
            setLogSaleSelected(logs)
        } else {
            let logs = logsDetailString as WcLogChipping
            setLogSelected(logs)
        }


    }
    const onBack = () => {
        goTo("/home-detail-logs")
    }
    const onCancel = () => {
        goTo("/home-detail-logs")
    }

    const onClickEdit = async () => {
        let log: any = LogSelected
        if (category === "sale") {
            log = LogSaleSelected
        }
        if (InputCubicSize !== "") {
            log.CubicSize = parseInt(InputCubicSize)
        }
        if (InputLoadMass !== "") {
            if (category !== "sale")
                log.LoadMass = parseInt(InputLoadMass)
        } if (InputQty !== "") {
            log.Quantity = parseInt(InputQty)
        }

        if (!window.confirm("Are you sure to edit this service log with WB-REF: " + log.WbRef + "?")) {
            return
        }

        let table = "chipping"
        if (category === "sale") {
            table = "sale"
        }

        await DeleteChippingLog("edit", table, log, (res: any) => {
            console.log("DeleteChippingLog edit result> ", res)
            alert("thank you record edited!")
            goTo("/home")
        })
        // alert("edit clicked")
    }

    const getSummaryKeyValue = (): { key: string, value: any }[] => {
        let record: any = LogSelected
        let qtyLabel = "WB Ref"
        let qtyValue: any = record.WbRef;
        if (category === "sale") {
            record = LogSaleSelected
            qtyLabel = "Delivery Note"
            qtyValue = LogSaleSelected.DeliveryNote
        }
        return [
            {
                key: "Client",
                value: record.Client
            },
            {
                key: "Project",
                value: record.Project
            },
            {
                key: "Vehicle",
                value: record.Vehicle
            },
            {
                key: "Driver",
                value: record.Driver
            },
            {
                key: "Delivery Date",
                value: moment(record.DeliveryDate, "YYYY-MM-DD").format("DD MMM YYYY")
            },
            {
                key: "WB Ref",
                value: record.WbRef,
            },
            {
                key: "Delivery Note",
                value: record.DeliveryNote,
            },
            {
                key: "Compost Maker",
                value: record.CompostMaker,
            },

        ]
    }

    const RenderInput = () => {
        return (
            <>
                <ListKeyVal list={getSummaryKeyValue()}/>


                <InputLabelForm
                    label={"Cubic Size"}
                    onChange={(val: any) => setInputCubicSize(val)}
                    labelColor={Colors.brand.gray}
                    type={"numeric"}
                    value={category === "chipping" ? LogSelected.CubicSize : LogSaleSelected.CubicSize}
                />
                {category === "chipping" &&
                    <InputLabelForm
                        label={"Load Mass"}
                        onChange={(val: any) => setInputLoadMass(val)}
                        labelColor={Colors.brand.gray}
                        type={"numeric"}
                        value={LogSelected.LoadMass}
                    />}
                <InputLabelForm
                    label={"Quantity"}
                    onChange={(val: any) => setInputQty(val)}
                    labelColor={Colors.brand.gray}
                    type={"numeric"}
                    value={category === "chipping" ? LogSelected.Quantity : LogSaleSelected.Quantity}
                />

                <MyContainer width={targetWidth - 60}>
                    <MyButton
                        width={(targetWidth) - 60}
                        bgColor={Colors.brand.gray}
                        labelColor={Colors.brand.white}
                        onClick={onClickEdit}
                    >Edit</MyButton>
                </MyContainer>
            </>
        )
    }

    return (
        <Container>
            <TopBar2 title={"Edit Log"}/>
            <NavBarBackCancel
                onBack={onBack}
                onCancel={onCancel}
            />
            <PageTitle
                title={`Edit Log Ref: [${category !== "sale" ? LogSelected.WbRef : LogSaleSelected.DeliveryNote}]`}
                color={Colors.brand.blue}/>

            {RenderInput()}

        </Container>
    )
}

const OldLabel = styled.label`
  text-align: left;
  margin-top: 10px;
`
const MyButton = styled.button<{ width: number, bgColor: string, labelColor: string }>`
  width: ${(props) => props.width}px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.labelColor};
  border: 1px solid white;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
`
const MyContainer = styled.div<{ width: number }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  min-height: 55px;
  padding: 5px;

`
const InnerBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
  text-align: left;
`
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;

`