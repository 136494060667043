/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

interface Props{
    handleInput2:any,
    data:{key:string,value:any}[],
    label:string
    defaultValue?:{key:string,value:any}
}

export default (props:Props)=> {
    const classes = useStyles();
    let {
        handleInput2,
        data,
        label,
    }=props;

    let options = [];
    for(let i in data){
        const row = data[i];
        let link = "";
        //link = FindImages(row.key, props.allData.Files);

        options.push({
            code:row.key,
            label:row.value,
            link:link
        })
    }
    const onChange=(e:any,v:any)=>{
        if(v===null){
            return
        }
        handleInput2(v.code)
    }

    return (
        <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={options}
            classes={{
                option: classes.option,
            }}
            autoHighlight
            getOptionLabel={option => option.label}
            renderOption={option => (
                <React.Fragment>
                    {option.label}
                </React.Fragment>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={"Choose a "+label}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}

                />
            )}
            onChange={(e,v)=>onChange(e,v)}
        />
    );
}
