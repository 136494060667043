import styled from "styled-components";
import Colors from "../../constants/Colors";
import { DatePicker} from "antd";


interface Props{
    label:string,
    onChange:any,
    type?:string
    labelColor?:string
    borderColor?:string
}

export default (props:Props)=>{

    return(
        <Container>
            <Label1 color={props.labelColor?props.labelColor:Colors.brand.blue}>
                {props.label}:
            </Label1>
            <DatePicker
                style={{
                    height: 40,
                    width: "100%"
                }}
                onChange={(date, dateString) => props.onChange(dateString)}
                placeholder={props.label}
            />
        </Container>
    )
}

const InputContent=styled.input<{borderColor:string}>`
  height: 35px;
  border-radius: 10px;
  border-color: ${(props)=>props.borderColor};
`
const Label1=styled.label<{color:string}>`
    font-weight: bold;
  font-size: 16px;
  color: ${(props)=>props.color};
`
const Container=styled.div`
    display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
  margin-top: 10px;
  
    `