import styled from "styled-components";
import {GetDimensions} from "../../services/service-global";
import {
    SvgActivitiesIcon,
    SvgCommunityIcon,
    SvgDashboardIcon,
    SvgMenuCloseIcon,
    SvgNotificationIcon,
    SvgProfileIcon
} from "../../assets/avgs/constant-icons";
import Colors from "../../constants/Colors";
import {useEffect, useState} from "react";

interface Props{
    isOpen:boolean
    onClick:any
    onClose:any
}
const {width,height}=GetDimensions()
const currentWidth = width>768?768:width;
export default (props:Props)=>{
    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>(GetDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const icoLeft = require("../../assets/images/reverselogo-10 .png")
    const data :{label:string,icon:any}[]=[
        {label:"Dashboard",icon:SvgDashboardIcon},
        {label:"Profile",icon:SvgProfileIcon},
        {label:"Community",icon:SvgCommunityIcon},
        {label:"Notification",icon:SvgNotificationIcon},
        {label:"Activities",icon: SvgActivitiesIcon}
    ]
    return (
        <CenteredComponentWrapper isOpen={props.isOpen}>
        <DropdownMenuContainer isOpen={props.isOpen}>
            <Panel1>
                <ContainerTitle>
                    <IconRight src={icoLeft} />
                    <CloseButton onClick={()=>props.onClose()}>{SvgMenuCloseIcon}</CloseButton>
                </ContainerTitle>
                <ContainerMenuContent>
                    {data.map(item=>{
                        return(
                            <InnerContentMenu onClick={()=>props.onClick(item.label)}>
                                {item.icon}
                                <LabelMenuName>{item.label}</LabelMenuName>
                            </InnerContentMenu>
                        )
                    })}

                </ContainerMenuContent>
            </Panel1>

            <Panel2 onClick={()=>props.onClose()}>
                <span>&nbsp;</span>
            </Panel2>


        </DropdownMenuContainer>
        </CenteredComponentWrapper>

    )
}

const Panel2=styled.div`
  min-width: 60px;
  max-width: 60px;
  height: ${height+5}px;
  background-color: black;
  opacity: 0.75;
  margin-right: -10px;
`
const Panel1=styled.div`
  width: ${currentWidth-20-30}px;
  height: ${height+5}px;
  background-color: white;
`
const CloseButton = styled.div`
    border-width: 0;
  background-color: transparent;
  cursor: pointer;
`
const LabelMenuName=styled.label`
  color: var(--gray-100, #121418);
  /* Paragraph_14px/Regular 14px */
  font-family: Lexend Deca;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  cursor: pointer;
`
const InnerContentMenu= styled.div`
    display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  &&&:hover {
    border: 1px solid  #e74c3c;
    background-color: #e74c3c; /* Change the background color on hover */
  };
  margin-bottom: 20px;
`
const ContainerMenuContent=styled.div`
  height: 335px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  width: ${currentWidth-20-30-59}px;
  padding: 10px;
  margin-top: 30px;
  padding-left: 20px;
`
const IconRight = styled.img`
    
`
const ContainerTitle = styled.div`
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:10px
`

const DropdownMenuContainer = styled.div<{isOpen:boolean}>`
  /*position: fixed;*/
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block flex' : 'none')};
  /* Add other styling properties like background color, width, etc. */
  background-color:transparent;


  width: ${currentWidth}px;
  max-width: 768px;
  border: 1px solid lightgray;
  height: ${height+5}px;
  border-radius: 10px;
  z-index: 100;
  fill: #FAFAFA;
  flex-direction: row;
  align-self: center;
`;

const BigContainer = styled.div<{ width: number, height: number ,isOpen:boolean}>`
  background-color: ${Colors.brand.black};
  width: ${(props) => props.width + "px"};
  min-height: ${(props) => props.height + "px"};
  overflow-y: auto;
  display: ${({ isOpen }) => (isOpen ? 'block flex' : 'none')};
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CenteredComponentWrapper = styled.div<{isOpen:boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  z-index: 999; /* Adjust the z-index to ensure it's above other content */
`;