import styled from "styled-components";


interface Props {
    title: string
    labelColor: string
    onClick: any,
}

export default (props: Props) => {
    return (
        <Label
            onClick={() => props.onClick()}
            color={props.labelColor}
        >
            {props.title}
        </Label>
    )
}
const Label = styled.nav<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 5px;
`