import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {GetDimensions} from "../services/service-global";
import TopBar from "../compoents/common/TopBar";
import NavBottom1 from "../compoents/common/NavBottom1";
import Colors from "../constants/Colors";
import {GetStorageRecord} from "../services/service-locastorage";
import {LoginType} from "../types/general-types";
import {setLoginState} from "../redux/actions";
import {useDispatch} from "react-redux";

const {width, height} = GetDimensions()
interface MyComponentProps {
    // No need to explicitly specify children prop
    children?: React.ReactNode;
    noMenu?: boolean,
    noBottom?:boolean
}

const Page: React.FC<MyComponentProps> = ({children, noMenu,noBottom}) => {
    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>(GetDimensions());
   const[Sync,setSync]=useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);





    return (
        <BigContainer {...windowSize}>
            <Container {...windowSize}>
                {noMenu?<TopBar />:null}
                {/*<NavButton
                data={[
                    {label: "Shipping", link: "#"},
                    {label: "Transport", link: "#"},
                    {label: "Finances", link: "#"},
                    {label: "School", link: "#"},
                    {label: "Exetat", link: "#"},
                ]}
                onSelect={setSelectedCategory}
                bgColor={"white"}
                selectedBackground={"#0F6F6E"}
                selectedColor={"white"}
                labelColor={"#0F6F6E"}
                borderColor={"#9BCFCF"}
                selectedItem={SelectedCategory}
                width={windowSize.width/3}
                height={34}
            />*/}
                {/*{pageHasMenu(noMenu) && <HeaderMenu/>}*/}
                {children}

                {noBottom?
                <NavBottom1 />:
                    null
                }

            </Container>
        </BigContainer>
    )
}


export default Page;


const Container = styled.div<{ width: number, height: number }>`
  background-color: ${Colors.brand.white} ;
  width: ${width-20}px;
  min-height: ${(props) => props.height + "px"};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 768px;
  overflow-y: auto;
`;
const BigContainer = styled.div<{ width: number, height: number }>`
  background-color: ${Colors.brand.black};
  width: ${(props) => props.width + "px"};
  min-height: ${(props) => props.height + "px"};
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0;
`