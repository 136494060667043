import AsyncStorage from '@react-native-async-storage/async-storage';

const  PrefixStorage = "@CMISWOODCHIPPING_"
export const SetStorageRecord = async (keyIn: string,values:any)=>{
    let myKey :string= `${PrefixStorage}${keyIn}`;
    try {
        await AsyncStorage.setItem(myKey, JSON.stringify(values))
    } catch (e) {
        // saving error
    }
}
export const GetStorageRecord = async (keyIn: string): Promise<any> => {
    let myKey = `${PrefixStorage}${keyIn}`
    let response:any = null;
    try {
        const jsonValue = await AsyncStorage.getItem(myKey)
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        // error reading value
        console.log("Error GetStorageRecord : ",e)
    }
    return response
}
export const RemoveStorageRecord = async (keyIn: string)=>{
    let myKey :string= `${PrefixStorage}${keyIn}`;
    //console.log("(::Profile::) ",values)
    try {
        await AsyncStorage.removeItem(myKey)
    } catch (e) {
        // saving error
    }
}

/*

let key = "profile"
        let res =await GetStorageRecord(key)
        if(!res){
            // profile saved not exist
            alert("profile store doesn't exit")
            //todo redirect to profile input form screen
            props.navigation.navigate("MiddlewareProfileCheck")
            return
        }


 */