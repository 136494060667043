import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Colors from "../../constants/Colors";
import {faClose,faBackward} from "@fortawesome/free-solid-svg-icons";


interface Props{
    onBack:any,
    onCancel:any
}

export default (props:Props)=>{
    return(
        <Container>
            <StyledIcon icon={faBackward} onClick={()=>props.onBack()}/>
            <StyledIcon icon={faClose} onClick={()=>props.onCancel()}/>
        </Container>
    )
}

const StyledIcon = styled(FontAwesomeIcon)`
  /* Add your styles here */
  color: ${Colors.brand.gray}; /* Set the desired color */
  font-size: 1rem; /* Set the desired font size */
  margin-right: 10px;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: lightgray;
  padding-bottom: 10px;
`