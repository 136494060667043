import {PostToPMIS} from "./service-http-post";
import {Client, WcDriver, WcVehicle} from "../types/data-types";
import moment from "moment";


export type PmisResponseType={
    RESULT:any,
    REQUEST:any
}
export type PmisResponseType1={
    RESULT:{
        feedback:any
    },
    REQUEST:any
}

export const getListOfDatesBasedOnCurrentWithAddOrMinusOption=(action:string,num:number):string[]=>{
    let data : string[]=[]
    let currentDate = new Date()
    data.push(moment(currentDate).format("YYYY-MM-DD"))
    if(action==="-"){
        for(let i =1;i<=num;i++){
            const newDate = new Date();
            newDate.setMonth(newDate.getMonth() - i);
            let out = moment(newDate).format("YYYY-MM-DD")
            data.push(out)
        }
    }
    /*let outDates:string[]=[];
    let index = data.length - 1
    for(let i=index;i>=0;i--){
        let dt = data[i]
        outDates.push(dt)
    }*/

    return data
}
export const buildComboDriver = (drivers:WcDriver[],org:string): { key: string, value: any }[] => {
    let ls: { key: string, value: any }[] = [];
    for (let i in drivers) {
        let row = drivers[i];
        if (row.Org !== org) {
            continue
        }
        ls.push({
            value: row.Name,
            key: row.Name
        })
    }
    return ls
}

export const buildComboCompostMaker = (data:Client[],org:string): { key: string, value: any }[] => {
    let ls: { key: string, value: any }[] = [];
    for (let i in data) {
        let row = data[i];
        if (row.Org !== org) {
            continue
        }
        ls.push({
            value: row.Name,
            key: row.Name
        })
    }
    return ls
}

export const buildComboVehicle = (vehicles:WcVehicle[],org:string): { key: string, value: any }[] => {
    let ls: { key: string, value: any }[] = [];
    for (let i in vehicles) {
        let row = vehicles[i];
        if (row.Org !== org) {
            continue
        }
        ls.push({
            value: row.RegNumber,
            key: row.RegNumber
        })
    }
    return ls
}
export const FetchDataConfig=async (org:string,username:string,feedback:any)=>{
    let endpoint="/woodchipping/config/data"
    await PostToPMIS({Org:org,username},endpoint,(response:any)=>{
        console.log("FetchDataConfig  response> ",response)
        let info :PmisResponseType1 = response as PmisResponseType1
        feedback(info.RESULT.feedback)
    })
}

export const FetchDashboardChippingMin=async (org:string,TargetDate:string,feedback:any)=>{
    let endpoint="/dashboard/chipping/logs/min"
    await PostToPMIS({Org:org,TargetDate},endpoint,(response:any)=>{
        console.log("FetchDataConfig  response> ",response)
        let info :PmisResponseType = response as PmisResponseType
        feedback(info.RESULT)
    })
}

export   const cleanUsername=(valueIn:string):string=>{
    valueIn = valueIn.trim();
    valueIn = valueIn.toLowerCase()
    return valueIn
}
export const GetListOptionsFromStruct=(data:any[],col:string):string[]=>{
    let info:string[]=[]
    for(let i in data){
        let row = data[i]
        if(typeof row[col]!=="undefined"){
            let item = row[col] as string
            info.push(item)
        }
    }
    return info
}
export const GetDimensions=():{width:number,height:number}=>{
    return {
        width: window.innerWidth - 10,
        height: window.innerHeight - 10,
    }
}
export const goTo =(link:string)=>{
    window.location.href=link;
}
export const pageHasMenu=(val:any):boolean=>{
    let displayMenu = true
    if(typeof val!=="undefined" && val){
        displayMenu = false
    }
    return displayMenu
}