import styled from "styled-components";
import Colors from "../../constants/Colors";


interface Props {
    onPress: any,
    options: string[],
    selectedProject: string,
}

export default (props: Props) => {

    return (
        <Container>
            {props.options.map(item => {
                return (
                    <Button
                        onClick={() => props.onPress(item)}
                        borderColor={item !== props.selectedProject ? Colors.brand.lightGray : Colors.brand.lightRed}>
                        {item}
                    </Button>
                )
            })}
        </Container>
    )
}

const Button = styled.button<{ borderColor: string }>`
  border: 1px solid ${(props) => props.borderColor};
  margin: 5px;
  min-height: 45px;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  text-align: justify;
  cursor: pointer;
  background-color: ${Colors.brand.gray};
  color: ${Colors.brand.white};
  font-weight: bold;
  font-size: 22px;
`
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
  width: 90%;
`