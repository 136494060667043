const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
export const lightBlack = '#171717';

export default {
  brand:{
    gray:'#383838',
    red:"#ed1d24",
    yellow:"#ffcb05",
    blue:"#0e3961",
    lightBlue:"#3dc2ff",
    lightRed:"#FF6359",
    background:"#ccc",
    green:"#00B050",
    black: '#000',
    white: '#fff',
    lightGray:"lightgray",
    orange:"orange"
  },
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    lightBlack : '#171717',
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  additional:{
    background:"#F5F5F5"
  }
};


export const PURPLE = '#7A02B2'
export const LIGHTGREY = '#F2F2F2'

export const GREY = 'rgb(228,224,238)'
export const PALEPURPLE = '#FEE6F6'
export const MEDIUMGREY = '#bbbbbb'
export const DARKGREY = '#828282'
export const MYBLUE='#3dc2ff'
export const RED='red'