import React from "react";
import Colors from "../../constants/Colors";
import styled from "styled-components";


interface Props{
    labelColor:string
    bgColor:string
}
export default (props:Props)=>{
    return(
        <CircleContainer bgColor={props.bgColor}>
            <Label1 color={props.labelColor}>CMIS</Label1>
        </CircleContainer>
    )
}

const Label1 = styled.label<{color:string}>`
  font-weight: bold;
  font-size: 24px;
  font-style: italic;
  color:${(props)=>props.color};
`
const CircleContainer = styled.circle<{bgColor:string}>`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: ${(props)=>props.bgColor};

  display: flex;
  justify-content: center;
  align-items: center;
`