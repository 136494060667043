import React, {useEffect, useState} from "react";
import Page from "./Page";
import CircleContainer from "../compoents/common/CircleContainer";
import Colors from "../constants/Colors";
import styled from "styled-components";
import PageContainer from "../compoents/common/PageContainer";
import PageTitle from "../compoents/common/PageTitle";
import InputLabelForm from "../compoents/common/InputLabelForm";
import CircleActionButton from "../compoents/common/CircleActionButton";
import {cleanUsername, GetDimensions, goTo} from "../services/service-global";
import {useDispatch} from "react-redux";
import {GetStorageRecord, SetStorageRecord} from "../services/service-locastorage";
import {LoginResponseType, LoginType} from "../types/general-types";
import {setLoginState} from "../redux/actions";
import {LoginUser} from "../services/service-auth";
import ErrorMessageLabel from "../compoents/common/ErrorMessageLabel";

import {CmisWcAuthRecord} from "../constants/common";
import {SaveCookiesJson} from "../services/service-cookies";

const {width, height} = GetDimensions()


const myHeight = (width/2)
export default (props: any) => {
    const [busy,setBusy]=useState(false)
    const [LoginError,setLoginError]=useState(false)
    const [LoginErrorMessage,setLoginErrorMessage]=useState("")
    const[InputUsername,setInputUsername]=useState("");
    const[InputPassword,setInputPassword]=useState("")

    const dispatch = useDispatch();

    useEffect(()=>{
        /**
         *  let check if the user have previous login
         */
        if(!busy){
            setBusy(true)
            GetStorageRecord("record").then(record=>{
                console.log("LOGINCHECK > ",record)
                if(!record){
                    return
                }
                let rec =JSON.parse(record) as LoginType;
                let msg =  `Hi ${rec.Name}, we notice that you have previous login\n 
                    Do you want to continue as :${rec.Email} ?`
                if(window.confirm(msg)){
                    dispatch(setLoginState(rec))
                    SaveCookiesJson(CmisWcAuthRecord,rec);
                    goTo("/home")
                }
            })
        }
    })

    const onClickSubmit=()=>{
        /**
         * Validation controller
         */

        console.info(`login issue, Username: ${InputUsername}\nPassword: ${InputPassword}`)
        if(InputUsername===""){
            alert("Sorry login input username requested!");
            return;
        }
        if(InputPassword===""){
            alert("Sorry login input password requested!");
            return;
        }

        onSubmit().then(null)

    }
    const onSubmit=async ()=>{
        let postData={
            Username       :cleanUsername(InputUsername),
            Password    :InputPassword.trim(),
        }
        await LoginUser(postData,(response:any)=> {
            let info : LoginResponseType = response as LoginResponseType
            console.log("submit reg> ", response)
            if(!info.RESULT.status){
                setLoginErrorMessage(response["error"])
                setLoginError(true)
                return
            }
            let record = info.RESULT.record as LoginType
            dispatch(setLoginState(record))
            SaveCookiesJson(CmisWcAuthRecord,record);


            let fullName=record.Name;
            let email=record.Username;
            let phone=record.Phone;
            let role=record.Role;
            let org=record.Org;
            /**
             * let save to local storage our login information
             * use the code bellow
             */
            SetStorageRecord("phone",phone)
            SetStorageRecord("fullName",fullName)
            SetStorageRecord("role",role)
            SetStorageRecord("email",email)
            SetStorageRecord("org",org)
            SetStorageRecord("record",JSON.stringify(record))
            /**
             * end of saving
             */


            alert(`Welcome,  valid login \n${record.Name}`)
            goTo("/home")
        })
    }
    return (
        <Page noBottom={false}>
            <PageContainer bgColor={Colors.brand.white}>
                <CircleContainer
                    bgColor={Colors.brand.blue}
                    labelColor={Colors.brand.white}
                />
                <PageTitle
                    title={"Login page"}
                    color={Colors.brand.blue}
                />

                <FormLogin>
                    <InputLabelForm
                       label={"Username"}
                       onChange={setInputUsername}
                    />
                    <InputLabelForm
                        label={"Password"}
                        onChange={setInputPassword}
                        type={"password"}
                    />

                    {LoginError&&(
                        // eslint-disable-next-line react/jsx-no-undef
                        <ErrorMessageLabel
                            message={LoginErrorMessage}
                            color={Colors.brand.red}
                            fontSize={18}
                        />
                    )}

                    <ContainerAction>
                        <CircleActionButton
                            bgColor={Colors.brand.gray}
                            title={"Submit"}
                            labelColor={Colors.brand.white}
                            onClick={()=>onClickSubmit()}
                            height={40}

                            radius={20}
                            noSign={true}
                        />
                    </ContainerAction>
                </FormLogin>
            </PageContainer>

        </Page>
    )
}

const ContainerAction =styled.div`
    display: flex;
    flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 100px;
  margin-top: 10px;
`
const FormLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`