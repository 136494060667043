
import {createStore} from "redux";
import rootReducer from "./rootReducer";

// react-redux-firebase config.ts
const rrfConfig = {
    userProfile: 'users'
    // useFirestoreForProfile: true // Firestore for ProfilePage instead of Realtime DB
}

const store   = createStore(
    rootReducer,
);
export default store;
