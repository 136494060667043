import styled from "styled-components";
import IconContainer from "../../assets/images/IconContainer";
import imgIco from "../../assets/images/IconGreen.png"
import imgMenu from "../../assets/images/IconMenu.png"
import imgShoppingBag from "../../assets/images/IconShoppingBag.png"
import imgCategory from "../../assets/images/IconCategory.png"

import React, {useState} from "react";
import Colors from "../../constants/Colors";
import DropdownMenu from "./DropdownMenuHorizontal";


interface Props{
    category?:string
    subCategory?:string
}
export default (props:Props)=>{
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const onSelectMenu=(menu:string)=>{
        toggleDropdown();
    }



    return(
        <BigContainer>


        <Container>
            <GroupIcons justifyContent={"flex-start"}>

                <IconContainer Img={imgIco} />
                <LabelSubCategory>{props.subCategory}</LabelSubCategory>
            </GroupIcons>

            <GroupIcons justifyContent={"flex-end"}>
                <IconContainer Img={imgShoppingBag} size={18}/>
                <IconContainer Img={imgCategory} size={18}/>
                <IconContainer Img={imgMenu} onClick={toggleDropdown}/>
            </GroupIcons>

        </Container>
        <div>
            <DropdownMenu
                isOpen={isDropdownOpen}
                onClick={onSelectMenu}
                onClose={toggleDropdown}
            />
        </div>


        </BigContainer>
    )
}

const  BigContainer=styled.div`
    display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${Colors.brand.white};
`
const LabelSubCategory =styled.label`
    font-size: 18px;
  color: ${Colors.brand.blue};
  font-weight: bold;
`

const GroupIcons =styled.div<{justifyContent:string}>`
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props)=>props.justifyContent} ;
`
const Container =styled.div`
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: 10px;
`