import {
    SET_LOGIN_STATE,
    SET_COMPANIES_STATE,
    SET_CLIENTS_STATE,
    SET_ITEMS_STATE,
    SET_SELECTED_COMPANY,
    SET_SELECTED_CLIENT,
    SET_SELECTED_ITEM
} from "./constant";

export const setLoginState = (payload: any) => {
    return {type: SET_LOGIN_STATE, payload}
}
export const setCompaniesState = (payload: any) => {
    return {type: SET_COMPANIES_STATE, payload}
}
export const setClientsState = (payload: any) => {
    return {type: SET_CLIENTS_STATE, payload}
}
export const setItemsState = (payload: any) => {
    return {type: SET_ITEMS_STATE, payload}
}
export const setSelectedCompany = (payload: any) => {
    return {type: SET_SELECTED_COMPANY, payload}
}
export const setSelectedClient= (payload: any) => {
    return {type: SET_SELECTED_CLIENT, payload}
}
export const setSelectedItem = (payload: any) => {
    return {type: SET_SELECTED_ITEM, payload}
}